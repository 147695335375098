<template>
	<div class="body">
		<Row :gutter="10">
			<Col>
			<Input placeholder="寻艾AI名称" v-model="RuleForm.title"></Input>
			</Col>
			<Col>
			<Button type="primary" @click="init()">搜索</Button>
			</Col>
		</Row>
		<Table :data="data" :columns="columns" style="margin-top: 20px;" :loading="table_loading">
			<template slot="describe" slot-scope="{row}">
				<Tooltip placement="left" :transfer="true" :content="row.describe" :max-width="200">
					<div class="table-tooltip">{{ row.describe }}</div>
				</Tooltip>
			</template>
			<template slot="thumb" slot-scope="{row}">
				<img :src="serveUrl+row.thumb" alt="" class="table-thumb">
			</template>
			<template slot="status" slot-scope="{row}">
				<i-switch v-model="row.status" :true-value="1" :false-value="0"
					@on-change="up_switch(row.status,row.id)">
					<span slot="open">开</span>
					<span slot="close">关</span>
				</i-switch>
			</template>
			<template slot="operation" slot-scope="{row}">
				<div style="display: flex;align-items: center;flex-wrap: nowrap;">
					<Button @click="edit(row.id)" type="primary">编辑</Button>
					<Button @click="edit_package(row.id,row.title)" type="success" style="margin-left: 10px">编辑套餐</Button>
				</div>
			</template>
		</Table>
		<Page @on-change="up_current_page" @on-page-size-change="up_page_size" :total="RuleForm.total"
			:page-size="RuleForm.page_size" :current="RuleForm.current_page" :page-size-opts="[10,15,20]" :show-total="true" :show-sizer="true"
			style="margin-top: 10px;text-align: right;"></Page>
		<Modal v-model="modal_show" title="编辑寻艾AI接口" ok-text="保存" :loading="edit_loading" @on-ok="edit_submit">
			<Form :model="edit_form" :rules="rules" ref="edit_form" :label-width="120" :label-colon="true">
				<FormItem label="名称" prop="title">
					<Input v-model="edit_form.title" style="width: 200px" placeholder="请输入api名称"></Input>
				</FormItem>
				<FormItem label="描述" prop="describe">
					<Input type="textarea" v-model="edit_form.describe" style="width: 200px" :autosize="true"
						placeholder="请填写api描述"></Input>
				</FormItem>
				<FormItem label="缩略图" prop="thumb">
					<img v-if="edit_form.thumb" :src="serveUrl+edit_form.thumb" alt="" class="fi-icon">
					<Uploads class="fi-upload" :data="{dir:'ai/temp'}" @upload_succ="uploads_succ"
						:action="apiUrl+'/adm/upload_img'"></Uploads>
				</FormItem>
				<FormItem label="post请求地址" prop="post_url">
					<Input v-model="edit_form.post_url" style="width: 200px;" placeholder="请输入post请求地址"></Input>
				</FormItem>
				<FormItem label="请求控制器" prop="method">
					<Input v-model="edit_form.method" style="width: 200px;" placeholder="请输入请求控制器"></Input>
				</FormItem>
			</Form>
		</Modal>
		<Modal :title="'编辑'+ package_form.title +'套餐'" v-model="package_model" :width="68" ok-text="保存"
			@on-ok="package_submit" :loading="package_loading">
			<Form :model="package_form.title" :label-width="80" :label-colon="true">
				<FormItem label="AI名称">
					<Input v-model="package_form.title" style="width: 100px;" disabled></Input>
				</FormItem>
				<FormItem label="系列套餐">
					<div v-for="(item,index) in package_form.package_array" :key="index" class="package-array">
						<span>类型名称：</span><Input v-model="item.label" style="width: 250px;margin-right: 20px;"
							placeholder="套餐类型名称 如: 免费试用包、100次"></Input>
						<span>ai次数：</span><Input v-model="item.amount" style="width: 100px;margin-right: 20px;"
							type="number" placeholder="ai次数"></Input>
						<span>单价金额：</span><Input v-model="item.price" style="width: 100px;margin-right: 20px;"
							type="number" placeholder="单价"></Input>
						<span>是否开启固定金额：</span>
						<i-switch v-model="item.status" :true-value="1" :false-value="0">
							<span slot="open">开</span>
							<span slot="close">关</span>
						</i-switch>
						<span style="margin-left: 20px;">是否开启多数量：</span>
						<i-switch v-model="item.is_num" :true-value="1" :false-value="0">
							<span slot="open">开</span>
							<span slot="close">关</span>
						</i-switch>
						<span style="margin-left: 20px;">是否开启限购一次：</span>
						<i-switch v-model="item.is_limit" :true-value="1" :false-value="0">
							<span slot="open">开</span>
							<span slot="close">关</span>
						</i-switch>
						<Button v-if="index == 0" type="primary" icon="ios-add" style="width: 70px;margin-left: 20px"
							@click="add_package"></Button>
						<Button v-if="index != 0" type="error" icon="ios-trash-outline"
							style="width: 70px;margin-left: 20px" @click="del_package(index,item.id)"></Button>
					</div>
				</FormItem>
			</Form>
		</Modal>
	</div>
</template>

<script>
	import Uploads from '../../../components/common/Uploads.vue'
	export default {
		components: {
			Uploads
		},
		data() {
			return {
				table_loading: false,
				modal_show: false,
				apiUrl: this.ApiUrl,
				serveUrl: this.serveUrl,
				package_loading: true,
				package_model: false,
				edit_id: null, //编辑ID
				package_id: null, //编辑aiID
				edit_loading: true, //modal保存按钮的加载状态
				RuleForm: {
					title: '', //ai名称
					current_page: 1, //当前页码
					total: 0, //总页数
					page_size: 10, //每页条数
				},
				package_form: {
					title: null,
					package_array: [{
						label: '免费试用包', //套餐类型如 api试用包、100次
						amount: 100, //规格
						is_num: 0, //是否开启多数量，0否 1是
						status: 0, //是否固定金额
						price: 100, //购买单价
					}]
				},
				//编辑的表单数据
				edit_form: {
					title: '', //api名称
					describe: '', //api描述
					thumb: '', //缩略图
					post_url: '', //api请求地址
					method: '', //请求控制器
				},
				rules: {
					title: [{
						required: true,
						message: '请输入api名称',
						trigger: 'blur'
					}],
					describe: [{
						required: true,
						message: '请输入api描述',
						trigger: 'blur'
					}],
					thumb: [{
						required: true,
						message: '请上传api缩略图',
						trigger: 'change'
					}],
					post_url: [{
						required: true,
						message: '请输入post请求地址',
						trigger: 'blur'
					}],
					method: [{
						required: true,
						message: '请输入api请求方法',
						trigger: 'blur'
					}],
				},
				data: [],
				columns: [{
					title: 'ID',
					key: 'id',
					minWidth: 100,
					align: 'center'
				}, {
					title: 'AI名称',
					key: 'title',
					minWidth: 150,
					align: 'center'
				}, {
					title: 'AI描述',
					slot: 'describe',
					minWidth: 200,
					align: 'center'
				}, {
					title: '缩略图',
					slot: 'thumb',
					minWidth: 200,
					align: 'center'
				}, {
					title: '是否显示（云端后台）',
					slot: 'status',
					minWidth: 150,
					align: 'center'
				}, {
					title: '操作',
					slot: 'operation',
					minWidth: 100,
					align: 'center'
				}]
			}
		},
		created() {
			this.init()
		},
		methods: {
			//页码初始化时
			init() {
				var _this = this
				this.table_loading = true
				this.requestApi('/adm/ai_list', {
					RuleForm: this.RuleForm
				}).then(
					(res) => {
						_this.data = res.data.data
						_this.table_loading = false
						_this.RuleForm.current_page = res.data.current_page
						_this.RuleForm.total = res.data.total
						_this.RuleForm.page_size = res.data.per_page
					}
				)
			},
			//切换页码时
			up_current_page(e) {
				this.RuleForm.current_page = e
				this.init()
			},
			//点击配置切换时
			up_page_size(e) {
				this.RuleForm.page_size = e
				this.init()
			},
			//查询单条数据
			edit(id) {
				this.edit_id = id
				this.modal_show = true
				var _this = this
				this.requestApi('/adm/edit_ai', {
					id
				}).then(
					(res) => {
						if (res.code == 200) {
							_this.edit_form = res.data
						} else {
							_this.$Message.error(res.msg)
							_this.modal_show = false
							_this.init()
						}
					}
				)
			},
			//编辑ai套餐
			edit_package(id, title) {
				var _this = this
				this.package_form.title = title
				this.package_id = id
				this.package_model = true
				this.requestApi('/adm/find_ai_package', {
					id
				}).then(
					(res) => {
						_this.package_form.package_array = res.data
					}
				)

			},
			//保存修改的ai套餐
			package_submit() {
				let _this = this

				this.requestApi('/adm/preservation_ai_package',{data: this.package_form}).then(
					(res) => {
						_this.$Message.success(res.msg)
						_this.package_loading = false
						_this.package_model = false
						setTimeout(function() {
							_this.package_loading = true
						},100)
					}
				)
			},
			//点击保存按钮
			edit_submit() {
				var _this = this
				this.$refs.edit_form.validate(
					(res) => {
						if (res) {
							_this.requestApi('/adm/edit_save', {
								data: _this.edit_form
							}).then(
								(res) => {
									if (res.code == 200) {
										_this.$Message.success('保存成功')

									} else {
										_this.$Message.error('服务器错误')
									}
									_this.modal_show = false
									_this.edit_loading = false
									setTimeout(function() {
										_this.edit_loading = true
									}, 100)
									_this.init()
								}
							)
						} else {
							_this.$Message.error('请将内容填充完整')
							_this.edit_loading = false
							setTimeout(function() {
								_this.edit_loading = true
							}, 100)
						}
					}
				)
			},
			//上传图片回调
			uploads_succ(e) {
				this.edit_form.thumb = e.pic_url
			},
			//改变状态
			up_switch(status, id) {
				var _this = this
				this.requestApi('/adm/up_ai_status', {
					status,
					id
				}).then(
					(res) => {
						if (res.code == 200) {
							_this.$Message.success(res.msg)
						} else {
							_this.$Message.error(res.msg)
						}
					}
				)
			},
			//添加系列套餐
			add_package() {
				var _this = this
				let data = {
					aid: this.package_id,
					label: '',
					amount: '',
					status: 1,
					is_num: 1,
					price: 0
				}
				this.requestApi('/adm/add_ai_package', {
					data
				}).then(
					(res) => {
						if (res.code == 200) {
							data.id = res.data
							_this.package_form.package_array.push(data)
						} else {
							_this.$Message.error(res.msg)
						}

					}
				)

			},
			//删除系列套餐
			del_package(index,id) {
				var _this = this
				console.log(id)
				this.$Modal.confirm({
					title: '删除ai套餐规格',
					content: '确定删除该规格吗',
					okText: '确定',
					onOk: () => {
						_this.requestApi('/adm/del_ai_package', {
							id
						}).then(
							(res) => {
								if (res.code == 200) {
									_this.package_form.package_array.splice(index, 1)
								} else {
									_this.$Message.error(res.msg)
								}
							}
						)
					}
				})

			}
		}
	}
</script>

<style scoped="scoped">
	.body {
		padding: 10px;
		background-color: #fff;
	}

	.table-tooltip {
		width: 200px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.table-thumb {
		width: 50%;
		height: auto;
		margin-top: 5px;
	}

	.fi-upload {
		float: left;
		width: 77px;
		height: 77px;
		box-sizing: border-box;
	}

	.fi-icon {
		display: block;
		float: left;
		margin-right: 12px;
		width: 30%;
		height: auto;
	}

	.package-array {
		margin-bottom: 20px;
	}
</style>
